import ButtonRounded from '@/components/atoms/Button/ButtonRounded'
import InputField from '@/components/atoms/InputField'
import { useTypedForm } from '@/hooks/useTypedForm'
import { TAllFormValues } from '@/react-hook-form/types'
import React from 'react'
import { Controller, SubmitHandler } from 'react-hook-form'

interface TNotificationForm {
    onSubmit: SubmitHandler<TAllFormValues['AddEditNotification']>
    onClose: () => void
}

function AddEditNotification(props: TNotificationForm) {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useTypedForm('AddEditNotification')

    return (
        <form onSubmit={handleSubmit(props.onSubmit)} className="mt-40 px-50">
            <div>
                <Controller
                    render={({ field: { ref, ...rest } }) => {
                        return (
                            <InputField
                                {...rest}
                                size="small"
                                id="name"
                                // FIXME: improved placeholder
                                placeholder="件名を入力"
                                isRequired
                                label="件名"
                                error={!!errors.name?.message ?? false}
                                helperText={errors.name?.message}
                            />
                        )
                    }}
                    name="name"
                    control={control}
                />
            </div>
            <div className="mt-20">
                <Controller
                    render={({ field: { ref, ...rest } }) => {
                        return (
                            <InputField
                                {...rest}
                                size="small"
                                id="url"
                                placeholder="URLを入力"
                                isRequired
                                label="URL"
                                error={!!errors.url?.message ?? false}
                                helperText={errors.url?.message}
                            />
                        )
                    }}
                    name="url"
                    control={control}
                />
            </div>
            <div className="mt-40 mb-10 flex-center">
                <ButtonRounded className="company-form-btn" type="submit" types="filled">
                    決定
                </ButtonRounded>
                <ButtonRounded className="company-form-btn ml-20" types="outline" onClick={props.onClose}>
                    キャンセル
                </ButtonRounded>
            </div>
        </form>
    )
}

export default AddEditNotification
